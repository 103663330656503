<template>
  <div style="padding: 15px">
    <!-- 头部 -->
    <el-row style="padding-bottom: 10px">
      <el-col :span="24">
        <el-page-header @back="goSelect" content="报警历史记录">
        </el-page-header>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="select">
      <el-col :span="6">
        <el-select
          v-model="handleStatus"
          clearable
          placeholder="请选择处理状态"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option> </el-select
      ></el-col>
      <el-col :span="6" style="padding-left: 0px; padding-right: 10px">
        <el-select v-model="typeName" clearable placeholder="请选择设备类型">
          <el-option
            v-for="item in deviceType"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option> </el-select
      ></el-col>

      <el-col :span="6" style="padding-right: 0px">
        <el-input v-model="deviceId" placeholder="请输入设备号"></el-input>
      </el-col>

      <el-col :span="6" style="padding-right: 0px">
        <el-input v-model="valueName" placeholder="请输入设备属性"></el-input>
      </el-col>

      <el-col
        :span="6"
        style="padding-left: 0px; padding-right: 0px; margin-left: 10px"
      >
        <el-button
          icon="el-icon-search"
          @click="getAllByHandleStatus"
          type="primary"
        >
          查询
        </el-button>

        <el-button type="success" @click="aKeyProcess">一键处理</el-button>

        <!-- 导出按钮 -->
        <el-button type="success" @click="export2Excel"
          >导出全部报警历史</el-button
        >
      </el-col>
    </el-row>

    <!-- 表格 -->
    <el-table
      :data="deviceHistoryStatuss"
      :header-cell-style="{ textAlign: 'center', background: '#eef1f6' }"
      :cell-style="{ textAlign: 'center' }"
      :row-class-name="tableRowClassName"
      :border="true"
      style="width: 100%"
    >
      <el-table-column
        fixed
        type="index"
        :index="indexMethod"
        label="序号"
        width="50"
      >
      </el-table-column>
      <el-table-column prop="deviceId" label="设备号" width="190">
      </el-table-column>
      <el-table-column prop="typeName" label="设备类型" width="180">
      </el-table-column>
      <el-table-column prop="valueName" label="设备属性" width="180">
      </el-table-column>

      <el-table-column prop="companyName" label="绑定公司" width="280">
      </el-table-column>
      <el-table-column prop="note" label="备注" width="280"> </el-table-column>

      <el-table-column prop="value" label="设备状态" width="180">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180">
      </el-table-column>
      <el-table-column prop="handleStatus" label="处理状态" width="180">
      </el-table-column>
      <el-table-column prop="processType" label="处理类型" width="180">
      </el-table-column>
      <el-table-column prop="process" label="处理说明"> </el-table-column>

      <el-table-column fixed="right" label="操作" width="330">
        <template slot-scope="scope">
          <el-button
            @click="showVideo(scope.row)"
            :type="scope.row.videoUrl != null ? 'success' : 'warning'"
            size="mini"
            >视频</el-button
          >
          <el-button @click="handleClick(scope.row)" type="success" size="mini"
            >处理</el-button
          >
          <el-button
            type="primary"
            @click="getOneDeviceHistoryValue(scope.row)"
            size="mini"
            >查看</el-button
          >
          <el-button @click="toCRT(scope.row)" type="primary" size="small"
            >CRT</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="详情" :visible.sync="dialogc" width="60%">
      <img :src="baseUrl + actualUrl" style="width: 70%; margin: 0 auto" />
    </el-dialog>

    <!-- 分页组件 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="5"
      layout="total, prev, pager, next , jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 处理框 -->
    <div>
      <el-dialog
        title="处理设备状态"
        :visible.sync="dialogFormVisible"
        width="64%"
      >
        <el-form :model="deviceHistoryStatus">
          <el-form-item label="设备型号:">
            <el-input
              class="inPutStyle"
              size="small"
              style="width: 180px"
              v-model="deviceHistoryStatus.deviceId"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型:">
            <el-input
              class="inPutStyle"
              size="small"
              style="width: 180px"
              v-model="deviceHistoryStatus.typeName"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="设备属性:">
            <el-input
              class="inPutStyle"
              size="small"
              style="width: 180px"
              v-model="deviceHistoryStatus.valueName"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="设备状态:">
            <el-input
              class="inPutStyle"
              size="small"
              style="width: 180px"
              v-model="deviceHistoryStatus.value"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间:">
            <el-input
              class="inPutStyle"
              size="small"
              style="width: 180px"
              v-model="deviceHistoryStatus.createTime"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="处理状态:">
            <el-input
              disabled
              class="inPutStyle"
              size="small"
              style="width: 180px"
              v-model="deviceHistoryStatus.handleStatus"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span>处理过程:</span><br />
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入处理说明"
              v-model="deviceHistoryStatus.process"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <span>选择类型：</span>
            <el-radio-group v-model="processType">
              <el-radio :label="1">真实</el-radio>
              <el-radio :label="2">误报</el-radio>
              <el-radio :label="3">检修</el-radio>
              <el-radio :label="4">测试</el-radio>
            </el-radio-group></el-form-item
          >
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="clearProcessType">取 消</el-button>
          <el-button type="primary" @click="updateHandleStatus"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 查看框 -->
    <div v-if="dialogTableVisibleValue">
      <el-dialog title="具体数据" :visible.sync="dialogTableVisibleValue">
        <el-table
          :data="deviceHistoryValues"
          :header-cell-style="{ textAlign: 'center', background: '#eef1f6' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column
            property="deviceId"
            label="设备型号"
            width="150"
          ></el-table-column>
          <el-table-column
            property="typeName"
            label="设备类型"
            width="150"
          ></el-table-column>
          <el-table-column
            property="valueName"
            label="属性"
            width="150"
          ></el-table-column>
          <el-table-column
            property="value"
            label="值"
            width="150"
          ></el-table-column>
          <el-table-column
            property="createTime"
            label="创建时间"
            width="150"
          ></el-table-column>
        </el-table>
        <div>
          <line-chart
            v-if="isShowTu"
            :dateList="dateList"
            :valueList="valueList"
            :hValue="hValue"
            :lValue="lValue"
          ></line-chart>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import companySystem from "@/api/managementApi/CompanyInfo";
import BaseData from "@/assets/config/BaseData.js";
// import deviceInfo from "../../api/manageApi/DeviceInfoManagement";
import { export_json_to_excel } from "../../introduce/Export2Excel";

import DeviceHistoryStatus from "../../api/manageApi/DeviceHistoryStatus";
import DeviceHistoryValue from "../../api/manageApi/DeviceHistoryValue";
import DeviceTypeManagement from "../../api/manageApi/DeviceTypeManagement";
import crtDeviceCoordinate from "../../api/manageApi/CrtDeviceCoordinate";
import lineChart from "@/components/echarts/lineChart";
export default {
  components: {
    lineChart,
  },
  data() {
    return {
      actualUrl: "",
      baseUrl: BaseData.baseUrl,

      dialogc: false,
      getCompanyNameById: {},
      companyNameById: {},
      companyInfoList: [], // 公司信息

      deviceHistoryStatuss1: [],
      deviceHistoryStatuss: [], // 状态历史表

      deviceHistoryStatus: {
        deviceId: "",
        typeName: "",
        valueName: "",
        createTime: "",
        handleStatus: "",
        process: "",
        processType: "",
      },

      deviceHistoryValues: [{}], //实时值历史表
      options: [
        // 查询处理状态下拉框数据
        {
          value: "已处理",
          label: "已处理",
        },
        {
          value: "未处理",
          label: "未处理",
        },
      ],

      deviceType: [], // 查询设备类型下拉框数据
      handleStatus: "", //处理状态
      typeName: "", //设备类型
      deviceId: "", //设备号
      valueName: "", //设备属性
      total: 0, //总页数
      currentPage: 1, //第几页
      dialogFormVisible: false, //是否显示处理弹框
      dialogTableVisibleValue: false, //是否显示查看框
      processType: 0, //处理状态（1为真实  2为误报  3为检修  4为测试）

      dateList: [], //折线图时间显示数组
      valueList: [], //折线图值显示数组
      hValue: "", //折线图高阈值显示
      lValue: "", //折线图低阈值显示
      isShowTu: false, //是否显示折线图
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.$emit("routerIsHistoryFunction", true);
  },
  destroyed() {
    var that = this;
    that.$emit("routerIsHistoryFunction", false);
  },
  methods: {
    init() {
      this.getCompanyInfo();
    },
    handle() {
      this.dialogc = true;
    },

    // 一键处理
    aKeyProcess() {
      this.$confirm("此操作将一键处理所有异常设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeviceHistoryStatus.updateAll().then((resp) => {
          if (resp.data.success) {
            //成功
            this.$message({
              message: "处理成功！",
              type: "success",
            });
            this.$router.go(0);
            this.init();
          } else {
            this.$message.error("处理失败！");
            this.init();
          }
        });
      });
    },
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
        this.getAll();
      });
    },
    goSelect() {
      this.$router.push("/Select");
    },

    tableRowClassName({ row, rowIndex }) {
      // 报警为红色，故障为黄色

      if (row.value.indexOf("警") !== -1 && row.handleStatus === "未处理") {
        return "warning-row";
      } else if (
        (row.value.indexOf("失联") !== -1 ||
          row.value.indexOf("故障") !== -1) &&
        row.handleStatus === "未处理"
      ) {
        return "success-row";
      } else {
        return "";
      }
    },
    getAll() {
      //获取所有历史表中的数据(分页)
      DeviceHistoryStatus.getAll(
        this.currentPage,
        this.handleStatus,
        this.typeName,
        this.deviceId,
        this.valueName
      ).then((resp) => {
        if (resp) {
          this.deviceHistoryStatuss = resp.data.data.list;
          this.total = resp.data.data.total;

          for (let i = 0; i < this.deviceHistoryStatuss.length; i++) {
            this.deviceHistoryStatuss[i].companyName =
              this.companyNameById[this.deviceHistoryStatuss[i].companyId];

            if (this.deviceHistoryStatuss[i].note == "null") {
              this.deviceHistoryStatuss[i].note = "";
            }
          }
        }
      });

      //获取所有设备类型
      DeviceTypeManagement.getAllDeviceType().then((resp) => {
        if (resp) {
          this.deviceType = resp.data.data.list;
        }
      });
      // 获取所有设备信息导出
      DeviceHistoryStatus.getAllHistory().then((resp) => {
        if (resp) {
          this.deviceHistoryStatuss1 = resp.data.data.list;
        }
      });
    },
    toCRT(row) {
      crtDeviceCoordinate.getCoordinateByDeviceId(row.deviceId).then((res) => {
        if (res.data.success) {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
            },
          });
        } else {
          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        }
      });
    },
    //视频
    showVideo(row) {
      if (row.videoUrl) {
        window.location.href = row.videoUrl;
      } else {
        //提示信息
        this.$message({
          type: "warning",
          message: "该设备未绑定视频设备！",
        });
      }
    },
    indexMethod(index) {
      // 序号递增
      const currentPage = this.currentPage; // 当前页码
      const pageSize = 5; // 每页条数
      return index + 1 + (currentPage - 1) * pageSize; // 返回表格序号
    },
    handleCurrentChange(val) {
      // 第几页
      this.currentPage = val;
      this.init();
    },
    handleClick(row) {
      //处理
      this.dialogFormVisible = true;
      this.deviceHistoryStatus = row;
    },
    updateHandleStatus() {
      //处理修改状态增加处理过程和类型
      this.dialogFormVisible = false;
      if (this.processType === 1) {
        this.deviceHistoryStatus.processType = "真实";
      } else if (this.processType === 2) {
        this.deviceHistoryStatus.processType = "误报";
      } else if (this.processType === 3) {
        this.deviceHistoryStatus.processType = "检修";
      } else if (this.processType === 4) {
        this.deviceHistoryStatus.processType = "测试";
      } else {
        this.dialogFormVisible = true;
        this.$message.error("您没有选择处理类型！");
      }
      if (
        this.deviceHistoryStatus.processType !== "" &&
        this.deviceHistoryStatus.processType != null
      ) {
        DeviceHistoryStatus.updateProcess(this.deviceHistoryStatus).then(
          (resp) => {
            if (resp.data.success) {
              //成功
              this.$message({
                message: "处理成功！",
                type: "success",
              });
              this.init();
            } else {
              this.$message.error("处理失败！");
              this.init();
            }
          }
        );
      }
      this.processType = 0;
      this.deviceHistoryStatus.processType = "";
    },
    clearProcessType() {
      //处理框点击取消清空数据
      this.dialogFormVisible = false;
      this.processType = 0;
      this.deviceHistoryStatus.process = "";
    },
    getAllByHandleStatus() {
      //条件查询
      this.init();
    },
    getOneDeviceHistoryValue(row) {
      //显示报警或故障时的具体数据

      if (row.typeName.indexOf("摄像头") != -1) {
        this.dialogc = true;
        this.actualUrl = row.notifySystemId;
      } else {
        this.isShowTu = false;
        DeviceHistoryValue.getOneByDeviceHistoryStatusId(row.id).then(
          (resp) => {
            if (resp.data.data.one.length != 0) {
              this.deviceHistoryValues = resp.data.data.one;
              setTimeout(() => {
                this.dialogTableVisibleValue = true;
              }, 100);
            } else {
              this.$message({
                dangerouslyUseHTMLString: true,
                message: "<strong>该设备没有实时值</strong>",
              });
            }
          }
        );
        DeviceHistoryValue.getDayValue(
          row.deviceId,
          row.value,
          row.createTime,
          row.typeId
        ).then((res) => {
          console.log(res);
          if (res.data.message == "不显示") {
            this.isShowTu = false;
          } else {
            this.dateList = res.data.data.dateList;
            this.valueList = res.data.data.valueList;
            this.hValue = res.data.data.hValue;
            this.lValue = res.data.data.lValue;
            this.isShowTu = true;
          }
        });
      }
    },
    //导出excel
    export2Excel() {
      require.ensure([], () => {
        const tHeader = [
          "设备号",
          "设备类型",
          "设备属性",
          "设备状态",
          "创建时间",
          "处理状态",
          "处理类型",
          "处理说明",
        ]; // 对应表格输出的中文title
        const filterVal = [
          "deviceId",
          "typeName",
          "valueName",
          "value",
          "createTime",
          "handleStatus",
          "processType",
          "process",
        ]; // 对应表格输出的数据

        const list = this.deviceHistoryStatuss1; // 表格data

        const data = this.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "报警历史记录"); // 对应下载文件的名字
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
};
</script>

<style lang="scss" >
.el-col-6 {
  width: auto;
}
.notify-warning {
  background: rgba(177, 58, 60, 0.979) !important;
  box-shadow: 3px 3px 2px rgb(235, 106, 106) !important;
}

.el-icon-warning {
  color: #fe2e2e !important;
}

.notify-fault {
  background: rgba(238, 217, 33, 0.5) !important;
  box-shadow: 3px 3px 2px rgb(208, 238, 138) !important;
}

.el-icon-info {
  color: #f3f781 !important;
}

.el-table .warning-row {
  background: #f78181;
}

.el-table .success-row {
  background: #faac58;
}

.el-pagination {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 5px;
}

.block {
  background: #fff;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.inPutStyle {
  width: 180px;
}

.select {
  margin-bottom: 10px;
}
</style>

