// 状态历史表api
import request from '../../../utils/request'
const api_name = '/device-history-status'

export default {
    getAll(currentPage, status, type, superDeviceId, superValueName) { //分页查询所有状态表中的数据
        let handleStatus = ''
        let typeName = ''
        let deviceId = ''
        let valueName = ""
        if (status != '') {
            handleStatus = "&handleStatus=" + status
        }
        if (type != '') {
            typeName = "&typeName=" + type
        }
        if (superDeviceId != '') {
            deviceId = "&deviceId=" + superDeviceId
        }
        if (superValueName != '') {
            valueName = "&valueName=" + superValueName
        }
        return request({
            url: `${api_name}` + '/getAll?number=' + currentPage + handleStatus + typeName + deviceId + valueName,
            method: 'get'
        })
    },
    updateProcess(deviceHistoryStatues) { //处理修改状态，并增加处理类型和说明
        return request({
            url: `${api_name}` + '/updateProcess',
            method: 'post',
            data: deviceHistoryStatues,
        })
    },
    getAllHistory() {
        return request({
            url: `${api_name}` + '/getAllHistory',
            method: 'get',
        })
    },

    updateAll() {
        return request({
            url: `${api_name}` + '/updateAll',
            method: 'post',
        })
    }


}