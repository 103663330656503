<template>
  <div>
    <div id="main" ref="chart"></div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  props: ["dateList", "valueList", "hValue", "lValue"],
  name: "charts",
  data() {
    return {
      data: {
        title: {
          text: "历史记录显示",
        },
        tooltip: {
          trigger: "none",
          axisPointer: {
            type: "cross",
          },
        },

        legend: {},

        grid: {
          top: 70,
          bottom: 50,
        },

        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return (
                  "Precipitation  " +
                  params.value +
                  (params.seriesData.length
                    ? "：" + params.seriesData[0].data
                    : "")
                );
              },
            },
          },
          data: this.dateList,
        },

        axisLine: {
          onZero: false,
          lineStyle: {
            color: ["#008c8c"],
          },
        },
        axisPointer: {
          label: {
            formatter: function (params) {
              return (
                "Precipitation  " +
                params.value +
                (params.seriesData.length
                  ? "：" + params.seriesData[0].data
                  : "")
              );
            },
          },
        },

        yAxis: {},
        series: [
          {
            name: "高阈值",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
            },
            data: [],
          },
          {
            name: "值",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
            },
            data: this.valueList,
          },
          {
            name: "低阈值",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
            label: {
              show: true,
            },
            data: [],
          },
        ],
      },
      hValueList: [], //折线图高阈值数组
      lValueList: [], //折线图低阈值数组
    };
  },
  created() {
  },
  mounted() {
    for (let i in this.dateList) {
      this.hValueList.push(this.hValue);
      this.lValueList.push(this.lValue);
    }
    if (this.hValue != "" && this.hValue != null && this.hValue != undefined) {
      this.data.series[0].data = this.hValueList;
    } else {
      this.data.series[0].name = "";
    }
    if (this.lValue != "" && this.lValue != null && this.lValue != undefined) {
      this.data.series[2].data = this.lValueList;
    } else {
      this.data.series[2].name = "";
    }
    echarts.init(this.$refs.chart).setOption(this.data);
  },
};
</script>

<style scoped>
#main {
  width: 100%;
  height: 50vh;
  box-sizing: border-box;
  margin: 10vh auto 0;
}
</style>

